import React, { useState } from 'react';

function SalesTable ({year, item, weather}){
    const [expandWeather, setExpandWeather] = useState(false)
    const total = item.sales[year].reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
    const cyTotal = item.sales["2024"].reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
    const cyLength = item.sales["2024"].filter(x => x).length

    const cumulativePercents = item.sales[year].map((sale, index) => (item.sales[year].slice(0,index+1).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)*100/total).toFixed(2))
    const netPercents = item.sales[year].map(sale => (sale/total * 100).toFixed(2))
    const labels = ['', 'Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8', 'Week 9', 'Total']
    console.log(cyTotal, cumulativePercents[cyLength-1])
    const cyPrediction = Math.round(cyTotal/(cumulativePercents[cyLength-1]/100))

    function getWeatherRows(weather) {
        return (<>
            <tr>
                <th>
                    High
                </th>
                    {weather.map(w => <td>{w.high.toFixed(0)}°</td>)}
            </tr>
            <tr>
                <th>
                    Low
                </th>
                {weather.map(w => <td>{w.low.toFixed(0)}°</td>)}
            </tr>
            <tr>
                <th>
                    Humidity
                </th>
                {weather.map(w => <td>{w.humidity.toFixed(0)}%</td>)}
            </tr>
            <tr>
                <th>
                    Cloud Cover
                </th>
                {weather.map(w => <td>{w.cloud.toFixed(0)}%</td>)}
            </tr>
            <tr>
                <th>
                    Precipitaion (in)
                </th>
                {weather.map(w => <td>{w.precipitation.toFixed(0)}</td>)}
            </tr>
        </>)
    }

    return (
        <>
            <div>Sales for {year}</div>
            <div>
                <table style={{margin:"auto"}}>
                    <thead>
                        <tr>
                            {labels.map((label,index) => <th>{label}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Item Sales</th>
                            {item.sales[year].map(sale => <td>{sale}</td>)}
                            <td>{total}</td>
                        </tr>
                        {weather && <tr>
                            <th onClick={()=> setExpandWeather(!expandWeather)}>Weather</th>
                            {weather.map(w => <td>{w.average.toFixed(0)}°</td>)}
                        </tr>}
                        {weather && expandWeather && getWeatherRows(weather)}
                        <tr>
                            <th>Percent of total sales</th>
                            {netPercents.map(percent => <td>{percent}</td>)}
                        </tr>
                        <tr>
                            <th>Current Year Sales</th>
                            {item.sales["2024"].map((sale, index) => sale ? <td>{sale}</td>: <td style={{color: "red"}}>{Math.round(cyPrediction*netPercents[index]/100)}</td>)}
                            <td>{cyPrediction}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default SalesTable
