import React, { useState } from 'react';
import ReactApexChart from "react-apexcharts";
import Item from "./Item";

const salesHistory = [
    {
        "description": "Total Sales",
        "sales": {
            "2017": [6280, 7636, 6636, 7485, 6815, 9575, 6824, 7853, 10635],
            "2018": [5081, 7730, 2490, 5500, 4995, 9239 , 8965, 10128, 10562],
            "2019": [4273, 7645, 6087, 5410, 7598, 7263, 8846, 9084, 7427 ],
            "2021": [4935, 11034, 9367, 9361, 10162, 10681, 12235, 11691, 14112],
            "2022": [7325, 10875, 8177,11452,13062,5449,10360, 10960, 12710],
            "2023": [7799, 13493,7987,10940,1,11694,11633,12015,14210],
            "2024": [7749,13140,10530,9058,0,0,0,0,0]
        }
    },
    {
        "description": "Wax Hands",
        "sales": {
            "2017": [194, 237, 252, 292, 230, 286, 168, 261, 277],
            "2018": [157, 200, 37, 113, 135, 213, 327, 328, 244],
            "2019": [151, 224, 205, 211, 238, 256, 234, 296, 171],
            "2021": [103, 245, 198, 216, 272, 237, 270, 222, 237],
            "2022": [109, 221, 183,250,269,84,214,226,234],
            "2023": [160, 307,131,216,1,201,274,196,236],
            "2024": [125,249,193,205,0,0,0,0,0]
        }
    },
    {
        "description": "Infinity",
        "sales": {
            "2017": [17, 19, 12, 17, 15, 33, 13, 19, 23],
            "2018":  [8, 33, 9, 6, 20, 24, 36, 26, 31],
            "2019": [7, 30, 13, 11, 12, 17, 32, 27, 22],
            "2021": [7, 18, 17, 10, 11, 27, 21, 22, 32],
            "2022": [10, 16, 12,14,14,10,23,17,31],
            "2023": [6, 22,16,11,1,10,20,27,26],
            "2024": [18,14,10,14,0,0,0,0,0]
        }
    },
    {
        "description": "Shawl",
        "sales": {
            "2018":  [4, 7, 3, 7, 3, 11, 13, 15, 15],
            "2019": [8, 11, 15, 11, 21, 11, 15, 11, 23 ],
            "2021": [15, 23, 19, 15, 14, 8, 18, 14, 31],
            "2022": [16, 15, 13,24,23,12,21,17,25],
            "2023": [13, 35,29,19,1,28,18,22,27],
            "2024": [13,23,11,15,0,0,0,0,0]
        }
    },
    {
        "description": "Sash Belt (CHB)",
        "sales": {
            "2017": [8, 16, 15, 14, 9, 24, 27, 9, 12],
            "2018": [2, 12, 3, 15, 7, 18, 16, 7, 6],
            "2019": [8, 18, 10, 5, 8, 22, 16, 5, 13, ],
            "2021": [10, 23, 25, 18, 25, 32, 25, 22, 30],
            "2022": [27, 41, 17,45,26,18,39,23,48],
            "2023": [29, 34,23,38,1,45,17,23,42],
            "2024": [20,34,34,23,0,0,0,0,0]
        }
    },
    {
        "description": "Rectangle Veil",
        "sales": {
            "2017": [28, 37, 22,  17, 16, 22, 28, 21, 46],
            "2018": [22, 33, 16, 24, 19, 32, 28, 30, 38],
            "2019": [12, 24, 16, 13, 18, 24, 25,21, 16 ],
            "2021": [14, 28, 25, 30, 33, 24, 45, 31, 50 ],
            "2022": [38,38,27,31,43,15,32,37,35],
            "2023": [12, 30,19,34,1,39,39,48,35],
            "2024": [24,48,44,33,0,0,0,0,0]
        },
        "unitPrice": 80,
        "unitCost": 21.5
    },
    {
        "description": "Triangle Fringe",
        "sales": {
            "2017": [16, 21, 16, 20, 11,  22, 9, 14, 25],
            "2018": [8, 21, 6, 11, 9, 9, 13, 9,  11],
            "2019": [10, 19, 4, 4, 17, 12, 9, 17, 5, ],
            "2021": [5, 15, 24, 11, 14, 25, 15, 22, 6],
            "2022": [13,11,12,14,16,7,5,7,20],
            "2023": [5, 25,11,10,1,12,5,4,11],
            "2024": [4,9,8,11,0,0,0,0,0]
        },
        "unitPrice": 60,
        "unitCost": 16.5
    }
]

const weather = {
    "2017": [{"datetime":"2017-08-26","high":81,"low":66.9,"average":74.15,"humidity":68.15,"cloud":54.5,"precipitation":0},{"datetime":"2017-09-02","high":80.1,"low":61,"average":67.35,"humidity":84.65,"cloud":79,"precipitation":0.394},{"datetime":"2017-09-09","high":73,"low":59,"average":65.85,"humidity":70,"cloud":17.5,"precipitation":0},{"datetime":"2017-09-16","high":81,"low":68.7,"average":74.6,"humidity":85.5,"cloud":41.5,"precipitation":0.02},{"datetime":"2017-09-23","high":90,"low":68,"average":77.3,"humidity":75.2,"cloud":20.5,"precipitation":0},{"datetime":"2017-09-30","high":69.1,"low":55,"average":62.9,"humidity":66.19999999999999,"cloud":20,"precipitation":0},{"datetime":"2017-10-07","high":82,"low":66.9,"average":75.65,"humidity":88.95,"cloud":71,"precipitation":0.217},{"datetime":"2017-10-14","high":78.1,"low":64.9,"average":69,"humidity":91.25,"cloud":65.5,"precipitation":0},{"datetime":"2017-10-21","high":73,"low":53.1,"average":62.25,"humidity":83.35,"cloud":50,"precipitation":0}],
    "2018": [{"datetime":"2018-08-25","high":88,"low":66.9,"average":76,"humidity":68,"cloud":37,"precipitation":0},{"datetime":"2018-09-01","high":88,"low":78.1,"average":81.85,"humidity":75.15,"cloud":89,"precipitation":0.9450000000000001},{"datetime":"2018-09-08","high":80.1,"low":66,"average":70.6,"humidity":83.3,"cloud":100,"precipitation":2.362},{"datetime":"2018-09-15","high":82.9,"low":72,"average":75.95,"humidity":76.7,"cloud":81,"precipitation":0},{"datetime":"2018-09-22","high":81,"low":64.9,"average":70.80000000000001,"humidity":74.2,"cloud":83,"precipitation":1.4569999999999999},{"datetime":"2018-09-29","high":78.1,"low":63,"average":70.3,"humidity":68.94999999999999,"cloud":28.5,"precipitation":0},{"datetime":"2018-10-06","high":84,"low":68,"average":74.5,"humidity":79.9,"cloud":78.5,"precipitation":0},{"datetime":"2018-10-13","high":64.9,"low":51.1,"average":57.9,"humidity":63.85,"cloud":80,"precipitation":0.11800000000000001},{"datetime":"2018-10-20","high":69.1,"low":44.1,"average":57.55,"humidity":58.85,"cloud":59.5,"precipitation":0.059}],
    "2019": [{"datetime":"2019-08-24","high":81,"low":69.1,"average":75,"humidity":58,"cloud":39,"precipitation":0.02},{"datetime":"2019-08-31","high":89.1,"low":70,"average":79.15,"humidity":62,"cloud":52,"precipitation":0.02},{"datetime":"2019-09-07","high":84,"low":63,"average":74.5,"humidity":59.65,"cloud":41,"precipitation":0},{"datetime":"2019-09-14","high":86,"low":71.1,"average":78,"humidity":64.85,"cloud":61,"precipitation":0.059},{"datetime":"2019-09-21","high":87.1,"low":61,"average":76,"humidity":66.15,"cloud":26.5,"precipitation":0},{"datetime":"2019-09-28","high":84,"low":69.1,"average":75.44999999999999,"humidity":77.55000000000001,"cloud":53,"precipitation":0},{"datetime":"2019-10-05","high":73,"low":51.1,"average":63.8,"humidity":66.75,"cloud":71.5,"precipitation":0.059},{"datetime":"2019-10-12","high":68,"low":53.1,"average":60,"humidity":71.30000000000001,"cloud":68.5,"precipitation":0.039},{"datetime":"2019-10-19","high":61,"low":44.1,"average":56.15,"humidity":78.35,"cloud":73,"precipitation":0.846}],
    "2021": [{"datetime":"2021-08-28","high":86,"low":72,"average":78.05,"humidity":81.85,"cloud":82,"precipitation":0.157},{"datetime":"2021-09-04","high":79,"low":63,"average":71.7,"humidity":70.7,"cloud":53.5,"precipitation":0.256},{"datetime":"2021-09-11","high":84.9,"low":59,"average":72.25,"humidity":62.5,"cloud":18,"precipitation":0},{"datetime":"2021-09-18","high":80.1,"low":66.9,"average":74.44999999999999,"humidity":76.55,"cloud":26,"precipitation":0},{"datetime":"2021-09-25","high":75,"low":57,"average":66.35,"humidity":63.25,"cloud":17.5,"precipitation":0},{"datetime":"2021-10-02","high":79,"low":55,"average":68.1,"humidity":81.55,"cloud":41.5,"precipitation":0},{"datetime":"2021-10-09","high":73,"low":66.9,"average":68.9,"humidity":80.8,"cloud":99,"precipitation":0.02},{"datetime":"2021-10-16","high":79,"low":53.1,"average":63.6,"humidity":66.85,"cloud":23,"precipitation":0.236},{"datetime":"2021-10-23","high":66.9,"low":55,"average":60.349999999999994,"humidity":71.15,"cloud":68.5,"precipitation":0}],
    "2022": [{"datetime":"2021-08-28","high":86,"low":72,"average":78.05,"humidity":81.85,"cloud":82,"precipitation":0.157},{"datetime":"2021-09-04","high":79,"low":63,"average":71.7,"humidity":70.7,"cloud":53.5,"precipitation":0.256},{"datetime":"2021-09-11","high":84.9,"low":59,"average":72.25,"humidity":62.5,"cloud":18,"precipitation":0},{"datetime":"2021-09-18","high":80.1,"low":66.9,"average":74.44999999999999,"humidity":76.55,"cloud":26,"precipitation":0},{"datetime":"2021-09-25","high":75,"low":57,"average":66.35,"humidity":63.25,"cloud":17.5,"precipitation":0},{"datetime":"2021-10-02","high":79,"low":55,"average":68.1,"humidity":81.55,"cloud":41.5,"precipitation":0},{"datetime":"2021-10-09","high":73,"low":66.9,"average":68.9,"humidity":80.8,"cloud":99,"precipitation":0.02},{"datetime":"2021-10-16","high":79,"low":53.1,"average":63.6,"humidity":66.85,"cloud":23,"precipitation":0.236},{"datetime":"2021-10-23","high":66.9,"low":55,"average":60.349999999999994,"humidity":71.15,"cloud":68.5,"precipitation":0}],

}




function Sales(){
    const [selectedItem, setSelectedItem] = useState("Total Sales");
    const item = salesHistory.find(item => item.description === selectedItem)
    const series = Object.keys(item.sales).map(year =>{
        return {data:item.sales[year], name:year}
    })

    const options = {
        chart: {
            type: 'bar',
            height: 430
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        tooltip: {
            shared: true,
            intersect: false
        },
        xaxis: {
            categories: [1,2,3,4,5,6,7,8,9].map(week => `Week ${week}`)
        },
    }

    return (
        <>
            <div>YOLO SALES</div>
            <select onChange={event => setSelectedItem(event.target.value)}>
                {salesHistory.map(item => <option value={item.description}>{item.description}</option>)}
            </select>
            <Item item={item} key={item.description} weather={weather}/>

            <ReactApexChart options={options} series={series} type="bar" height={430} />
        </>

    )
}

export default Sales
