import SalesTable from "./SalesTable";
import {useState} from "react";

function Item(props){

    const item = JSON.parse(JSON.stringify(props.item))
    const defaultWeights = Object.keys(item.sales).reduce(function(acc, year){acc[year] = 1/6; return acc}, {})
    delete defaultWeights["2024"]
    const [weights, setWeights] = useState(defaultWeights)

    const updateWeight = (event, year) =>{
        const localWeights = JSON.parse(JSON.stringify(weights))
        localWeights[year] = event.target.value
        setWeights(localWeights)
    }
    item.sales["All Years"] = item.sales[2018].map((_, index) => {
        let sum = 0
        Object.keys(props.item.sales).filter(year => year < 2024 ).forEach( key => sum = sum + item.sales[key][index])
        return Number(sum/Object.keys(item.sales).filter(year => year < 2024 ).length).toFixed((0))
    })
    item.sales["Weighted Years"] = item.sales[2018].map((_, index) => {
        let sum = 0
        Object.keys(props.item.sales).filter(year => year < 2024 ).forEach( key => sum = sum + weights[key] * item.sales[key][index])
        return Number(sum).toFixed((0))
    })
    return (
        <>
            <div>{item.description}</div>
            {Object.keys(item.sales).filter(year => year !== "2024" ).sort().map(year => <SalesTable year={year} item={item} weather={props.weather[year]}/>)}
            {Object.keys(weights).map(year => <div><label>{year}</label><input onChange={e => updateWeight(e, year)} value={weights[year]}/></div>)}
            <button onClick={() => setWeights(defaultWeights)}>RESET</button>
            <div>Total = {Object.values(weights).reduce((sum, weight) =>{return sum + Number(weight)} , 0) } (This should be 1, no more, no less)</div>
        </>

    )
}

export default Item
