import logo from './logo.svg';
import './App.css';
import Sales from "./components/Sales";

function App() {
  return (
    <div className="App">
      <Sales></Sales>
    </div>
  );
}

export default App;
